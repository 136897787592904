/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import LinearProgress from "@material-ui/core/LinearProgress";

import { timelineCode } from "../../lib/constant";
import NumeroALetras from "../../lib/num-to-letters";

import Loading from "../../components/Loading";
import { Label } from "../../components/Text";
import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import StatusLabel from "../../components/Sales/StatusLabel";
import Header from "../../components/Sales/Details/Header";
import Timeline from "../../components/Timeline";

import Abono from "../../parts/sales/abono";

import { Container } from "../../assets/utils/styles/global";

import { details, sold_products, total_abonos } from "../../http/sales";
import { getTimeline } from "../../http/timeline";

var moment = require("moment");
var numeral = require("numeral");

function SalesDetails({ location, history }) {
  const id = location.state.id;

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [isLoadingProduct, setIsLoadingProduct] = useState(true);
  const [timeline, setTimeline] = useState({ data: [] });
  const [timelinePage, setTimelinePage] = useState(1);
  const [totalAbono, setTotalAbono] = useState(0);
  const [updateHistory, setUpdateHistory] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      try {
        const { data } = await details(id);
        setData(data[0]);
      } catch {}
      setIsLoading(false);
    }

    fetchData();
  }, [id]);

  useEffect(() => {
    async function fetchData() {
      setIsLoadingProduct(true);

      try {
        const { data } = await sold_products(id);
        setProducts(data);
      } catch {}
      setIsLoadingProduct(false);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchTimeline() {
      try {
        const response = await getTimeline({
          module_id: id,
          module_name: timelineCode.sales,
          page: timelinePage,
        });

        setTimeline(response.data);
      } catch {}
    }

    fetchTimeline();
  }, [id, timelinePage, updateHistory]);

  useEffect(() => {
    async function fetchTotalAbono() {
      try {
        const response = await total_abonos(id);
        const total = Number(data.total) - Number(response.data);
        setTotalAbono(total);
      } catch {}
    }

    fetchTotalAbono();
  }, [id, data]);

  const list = (key, value) => {
    return (
      <RowContainer>
        <Label weight={600}>{key}: </Label>
        <Label style={{ marginLeft: 8 }}>{value}</Label>
      </RowContainer>
    );
  };

  return (
    <Fragment>
      <Header
        updateHistory={updateHistory}
        loading={{ app: isLoading, product: isLoadingProduct }}
        products={products}
        data={data}
        setData={(item) => {
          setUpdateHistory(!updateHistory);
          setData(item);
        }}
        edit={() =>
          history.push({
            pathname: "add",
            state: { edit: true, data: data },
          })
        }
      />

      <Body>
        <Card>
          <Loading loading={isLoading} />

          {!isLoading && (
            <Fragment>
              {list("Código", data.number_code)}

              <RowContainer>
                <Label style={{ marginRight: 8 }} weight={600}>
                  Estado:
                </Label>

                <StatusLabel
                  data={{ ...data, description: data.status_name }}
                />
              </RowContainer>

              {list(
                "Fecha de creación",
                moment(data.created_at).format("DD/MM/YYYY")
              )}

              {data.payment_code === "PM005" &&
                list(
                  "Fecha de expiración",
                  moment(data.date_limit).format("DD/MM/YYYY")
                )}

              {list("Cliente", data.name)}
              {list("Descuento", data.discount_percent + "%")}
              {list("Vendedor", data.username)}
              {list("NCF", data.voucher_number)}
              {list("Moneda", data.coin)}
            </Fragment>
          )}
        </Card>

        <Card>
          <Loading loading={isLoading} />

          {!isLoading && (
            <Fragment>
              {list("Sub Total", numeral(data.subtotal).format("0,0.00"))}
              {list("Descuento", numeral(data.sale_discount).format("0,0.00"))}
              {list("ITBIS", numeral(data.itebis).format("0,0.00"))}
              {list("Total de la venta", numeral(data.total).format("0,0.00"))}
              {list("Total en letras", NumeroALetras(data.total))}
              {list(
                "Total de comisión",
                numeral(data.seller_cost).format("0,0.00")
              )}
              {list("Restante", numeral(totalAbono).format("0,0.00"))}
              {list("Comentarios", data.comments)}
              {list("Tipo de pago", data.payment_name)}
            </Fragment>
          )}
        </Card>
      </Body>

      <Body>
        <Timeline
          data={timeline}
          paginate={(page) => setTimelinePage(page)}
          style={{ width: "49%" }}
        />

        <Abono
          saleId={id}
          style={{ width: "49%" }}
          updateHistory={(total) => {
            setTotalAbono(totalAbono - Number(total));
            setUpdateHistory(!updateHistory);
          }}
        />
      </Body>

      <Container style={{ marginTop: 20, padding: "12px 0 0 0" }}>
        <Title>Productos</Title>

        {isLoadingProduct ? (
          <LinearProgress value="100" mode="determinate" />
        ) : (
          <Divider />
        )}

        <TableContainer style={{ marginTop: 16 }}>
          <Table>
            <tbody>
              <Tr>
                <Th>Código</Th>
                <Th>Nombre</Th>
                <Th>Costo del producto</Th>
                <Th>Costo deseado</Th>
                <Th>Costo del vendedor</Th>
                <Th>Cantidad a vender</Th>
              </Tr>

              {products.map((data, index) => (
                <Tr key={index}>
                  <Td>{data.code}</Td>
                  <Td>{data.name}</Td>
                  <Td>{data.sale_price}</Td>
                  <Td>{data.custom_price}</Td>
                  <Td>{data.seller_cost}</Td>
                  <Td>{numeral(data.quantity).format(0, 0)}</Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Container>
    </Fragment>
  );
}

export default withTheme(withRouter(SalesDetails));

const Title = styled.div`
  color: #545358;
  font-size: 20px;
  font-weight: 600;
  margin: 12px 0 12px 30px;
`;

const RowContainer = styled.div`
  display: flex;
  background-color: #f8f8f8;
  align-items: center;
  padding: 20px;

  :nth-child(even) {
    background-color: white;
  }
`;

const Divider = styled.div`
  height: 4px;
  background-color: #afdaa1;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Card = styled(Container)`
  padding: 0;
  width: 49%;
  margin-top: 20px;
  min-height: 300px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
