/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import styled, { withTheme } from "styled-components";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import { confirmAlert } from "../../Alert";
import { Tr, Table, Td, Th, TableContainer } from "../../Table";
import { Label } from "../../Text";
import Button from "../../Buttons/Button";
import AddProducts from "./Products/AddProducts";

import { Container as BodyContainer } from "../../../assets/utils/styles/global";

var numeral = require("numeral");

const Title = styled.div`
  color: #545358;
  font-size: 20px;
  font-weight: 600;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Divider = styled.div`
  height: 6px;
  background-color: #f8f8f8;
  margin: 8px -30px 0 -30px;
`;

function Products({ prices, products, client, ...props }) {
  const matches = useMediaQuery("(max-width:768px)");

  const [addModal, setAddModal] = useState(false);

  useEffect(() => {
    const result = calculation(products);
    props.setPrices(result);
  }, [client, products]);

  function validate(items) {
    const result = calculation(items);
    var myNumeral = numeral(client.credit_limit);
    var credit = myNumeral.value();

    if (Number(result.total) > credit) {
      confirmAlert(
        "warning",
        "El total pasa el limite de credito del cliente, ¿quisiera facturar de igual manera?"
      ).then((data) => {
        if (data.value) {
          props.setPrices(result);
          props.setProducts(items);
        } else {
          setAddModal(true);
        }
      });
    } else {
      props.setPrices(result);
      props.setProducts(items);
    }
  }

  function calculation(items) {
    var item = 0;
    var discount = 0;
    var itebis = 0;
    let seller_cost = 0;

    items.map((data) => {
      var tax = data.custom_price * Number(data.quantity);
      item = item + data.custom_price * Number(data.quantity);
      seller_cost =
        seller_cost +
        (data.custom_price - data.seller_cost) * Number(data.quantity);

      if (data.apply_tax === 1) {
        itebis = itebis + (tax / 100) * 18;
      }
    });

    discount = (item / 100) * client.discount;
    const total = item + itebis - discount;

    const result = {
      subtotal: item.toFixed(2),
      discount: discount.toFixed(2),
      itebis: itebis.toFixed(2),
      total: total.toFixed(2),
      seller_cost: seller_cost.toFixed(2),
    };

    return result;
  }

  return (
    <Fragment>
      <BodyContainer>
        <Header>
          <Title>Lista de productos agregados</Title>

          <Button
            disabled={client.value === 0}
            text="Agregar productos"
            action={() => setAddModal(true)}
            width={matches ? "100%" : "250px"}
            color="primary"
            icon="add"
            side="left"
            style={{ boxShadow: "none", color: "white", height: 40 }}
          />
        </Header>

        <Divider />

        {products.length !== 0 && (
          <TableContainer style={{ marginTop: 16 }}>
            <Table>
              <tbody>
                <Tr>
                  <Th>Código</Th>
                  <Th>Nombre</Th>
                  <Th>Costo del producto</Th>
                  <Th>Costo Deseado</Th>
                  <Th>Costo del vendedor</Th>
                  <Th>Cantidad a vender</Th>
                  <Th>Aplicar impuesto</Th>
                </Tr>

                {products.map((data, index) => (
                  <Tr key={index}>
                    <Td>{data.code}</Td>
                    <Td>{data.name}</Td>
                    <Td>{numeral(data.sale_price).format("0,0.00")}</Td>
                    <Td>{numeral(data.custom_price).format("0,0.00")}</Td>
                    <Td>{numeral(data.seller_cost).format("0,0.00")}</Td>
                    <Td>{numeral(data.quantity).format(0, 0)}</Td>
                    <Td>{data.apply_tax === 1 ? "Si" : "No"}</Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        )}

        <Divider />

        <Label weight={500} style={{ marginBottom: 4, marginTop: 16 }}>
          Subtotal: ${numeral(prices.subtotal).format("0,0.00")}
        </Label>

        <Label weight={500} style={{ marginBottom: 4, marginTop: 12 }}>
          Descuento: ${numeral(prices.discount).format("0,0.00")}
        </Label>

        <Label weight={500} style={{ marginBottom: 4, marginTop: 12 }}>
          ITBIS: ${numeral(prices.itebis).format("0,0.00")}
        </Label>

        <Label weight={500} style={{ marginBottom: 4, marginTop: 12 }}>
          Total: ${numeral(prices.total).format("0,0.00")}
        </Label>
      </BodyContainer>

      <AddProducts
        credit_note={props.credit_note}
        edit={props.edit}
        conduce={props.conduce}
        open={addModal}
        products={products}
        close={() => setAddModal(false)}
        setProducts={(items) => {
          validate(items);
          setAddModal(false);
        }}
      />
    </Fragment>
  );
}

export default withTheme(Products);
