import React, { useState, Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import styled from "styled-components";
import Select from "react-select";

import Checkbox from "@material-ui/core/Checkbox";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Label } from "../../components/Text";
import Calendar from "../../components/Calendar";
import { Tr, Table, Td, Th, TableContainer } from "../../components/Table";
import Button from "../../components/Buttons/Button";
import Divider from "../../components/Divider";
import NotPermissions from "../../components/NotPermissions";
import Clients from "../../components/Selects/Clients";
import Circle from "../../components/Buttons/Circle";

import { Container } from "../../assets/utils/styles/global";

import check_permission from "../../lib/check_permission";

import {
  productsReports,
  reports_client,
  reports_client_tax,
  export_excel,
  export_pdf,
  export_pdf_tax,
  export_excel_tax,
} from "../../http/reports";
import { list } from "../../http/codes";
import { listForSelect } from "../../http/taxReceipt";
import { usersListForSelect } from "../../http/users";

var moment = require("moment");
var numeral = require("numeral");

const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

function SalesGeneralReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalSale, setTotalSale] = useState(0);
  const [clientFilter, setClientFilter] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [filters, setFilters] = useState({});
  const [hasTax, setHasTax] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [sellers, setSellers] = useState([]);
  const [totalAbono, setTotalAbono] = useState(0);

  const matches = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await list("SL0");
        setStatusList(data);
      } catch {}
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await listForSelect();
        const options = [
          { label: "Con Comprobantes", value: "all" },
          { label: "Sin Comprobantes", value: "none" },
          ...data,
        ];
        setTaxes(options);
      } catch {}
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetch() {
      const { data } = await usersListForSelect();
      setSellers(data);
    }

    fetch();
  }, []);

  async function getList(value) {
    setIsLoading(true);
    setFilters(value);

    const from =
      value.from !== null ? moment(value.from).format("YYYY-MM-DD") : null;
    const to = value.to !== null ? moment(value.to).format("YYYY-MM-DD") : null;

    const object = { ...value, from: from, to: to };

    if (clientFilter) {
      value.taxId !== null ? setHasTax(true) : setHasTax(false);

      const { data } =
        value.taxId !== null
          ? await reports_client_tax(object)
          : await reports_client(object);

      setData(data.data.salesList);
      setTotalSale(data.data.totalSale);
      setTotalAbono(data.data.totalAbono);
    } else {
      const { data } = await productsReports(
        moment(value.from).format("YYYY-MM-DD"),
        moment(value.to).format("YYYY-MM-DD")
      );

      setData(data.data.salesList);
      setTotalSale(data.data.totalSale);
      setTotalAbono(data.data.totalAbono);
    }

    setIsLoading(false);
  }

  async function exportExcel() {
    if (hasTax) {
      const response = await export_excel_tax(data);
      window.open(`${process.env.REACT_APP_BASE_URL}${response.data.data}`);
    } else {
      const response = await export_excel(data);
      window.open(`${process.env.REACT_APP_BASE_URL}${response.data.data}`);
    }
  }

  async function exportPdf() {
    setLoadingPdf(true);

    const from =
      filters.from !== null ? moment(filters.from).format("YYYY-MM-DD") : null;
    const to =
      filters.to !== null ? moment(filters.to).format("YYYY-MM-DD") : null;

    const object = { ...filters, from: from, to: to };

    const response = hasTax
      ? await export_pdf_tax(object)
      : await export_pdf(object);

    window.open(`${process.env.REACT_APP_BASE_URL}${response.data.data}`);
    setLoadingPdf(false);
  }

  return (
    <Fragment>
      <Label weight="bold" style={{ marginBottom: 20, fontSize: 24 }}>
        Reportes
      </Label>

      <Container>
        <Formik
          initialValues={{
            from: null,
            to: null,
            client: { value: 0 },
            status: null,
            taxId: null,
            seller_id: null,
          }}
          onSubmit={(values) => getList(values)}
          enableReinitialize
        >
          {({ handleSubmit, values, handleChange, setFieldValue }) => (
            <Fragment>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    onChange={() => setClientFilter(!clientFilter)}
                    value="primary"
                    color="primary"
                  />

                  <Label>Aplicar mas filtros</Label>
                </div>

                <div>
                  <Circle
                    disabled={loadingPdf}
                    title="Exportar a pdf"
                    placement="left"
                    icon="description"
                    action={() => exportPdf()}
                  />

                  <Circle
                    title="Exportar a excel"
                    placement="left"
                    icon="import_export"
                    action={() => exportExcel()}
                  />
                </div>
              </div>

              <Divider style={{ margin: "12px -30px" }} />

              <Header>
                <Calendar
                  setDateState={handleChange("from")}
                  text="Desde"
                  value={values.from}
                  style={{ width: 250, margin: "0 16px 16px 0" }}
                />

                <Calendar
                  setDateState={handleChange("to")}
                  text="Hasta"
                  value={values.to}
                  style={{ width: 250, margin: "0 16px 16px 0" }}
                />

                {clientFilter && (
                  <>
                    <div style={{ width: 250, margin: "0 16px 16px 0" }}>
                      <Clients
                        isClearable
                        values={values.client}
                        onChange={(item) => {
                          item === null
                            ? setFieldValue("client.value", 0)
                            : setFieldValue("client", item);
                        }}
                      />
                    </div>

                    <div style={{ width: 250, margin: "0 16px 16px 0" }}>
                      <Select
                        isClearable
                        onChange={(item) => {
                          item === null
                            ? setFieldValue("status", null)
                            : setFieldValue("status", item.value);
                        }}
                        options={statusList}
                        placeholder="Estado"
                        styles={{
                          menu: (styles) => ({ ...styles, zIndex: 999 }),
                        }}
                        value={statusList.filter(
                          (item) => item.value === values.status
                        )}
                      />
                    </div>

                    <div style={{ width: 250, margin: "0 16px 16px 0" }}>
                      <Select
                        isClearable
                        onChange={(item) => {
                          item === null
                            ? setFieldValue("taxId", null)
                            : setFieldValue("taxId", item.value);
                        }}
                        options={taxes}
                        placeholder="Comprobante Fiscal"
                        styles={{
                          menu: (styles) => ({ ...styles, zIndex: 999 }),
                        }}
                        value={taxes.filter(
                          (item) => item.value === values.taxId
                        )}
                      />
                    </div>

                    <div style={{ width: 250, margin: "0 16px 16px 0" }}>
                      <Select
                        isClearable
                        onChange={(item) => {
                          item === null
                            ? setFieldValue("seller_id", null)
                            : setFieldValue("seller_id", item.value);
                        }}
                        options={sellers}
                        placeholder="Buscar por vendedor"
                        styles={{
                          menu: (styles) => ({ ...styles, zIndex: 999 }),
                        }}
                        value={sellers.filter(
                          (item) => item.value === values.seller_id
                        )}
                      />
                    </div>
                  </>
                )}
              </Header>

              <Divider style={{ margin: "0 -30px 12px -30px" }} />

              <Button
                text="Filtrar"
                action={handleSubmit}
                loading={isLoading}
                color="primary"
                isDisable={values.from === null || values.to === null}
                width={matches ? "100%" : "200px"}
              />
            </Fragment>
          )}
        </Formik>
      </Container>

      {check_permission("PE008", "canSee") ? (
        <>
          {clientFilter ? (
            <Container style={{ padding: "12px 0 0 0", marginTop: 20 }}>
              <TableContainer>
                <Table>
                  <tbody>
                    <Tr>
                      <Th>Código de factura</Th>
                      <Th>Nombre del cliente</Th>
                      <Th>RNC/Cédula</Th>
                      <Th>Fecha de creación de la factura</Th>
                      <Th>Fecha de vencimiento</Th>
                      <Th>NFC</Th>
                      <Th>Vendedor</Th>
                      {hasTax && <Th>Itebis</Th>}
                      {hasTax && <Th>Subtotal</Th>}
                      <Th>Total Vendido</Th>
                      <Th>Comisión</Th>
                    </Tr>

                    {data.map((data, index) => (
                      <Tr key={index}>
                        <Td>{data.number_code}</Td>
                        <Td>{data.name}</Td>
                        <Td>{data.document}</Td>
                        <Td>{data.created_at}</Td>
                        <Td>{data.date_limit}</Td>
                        <Td>{data.voucher_number}</Td>
                        <Td>{data.username}</Td>
                        {hasTax && (
                          <Td>{numeral(data.itebis).format("0,0.00")}</Td>
                        )}
                        {hasTax && (
                          <Td>{numeral(data.subtotal).format("0,0.00")}</Td>
                        )}
                        <Td>{numeral(data.total).format("0,0.00")}</Td>
                        <Td>{numeral(data.seller_cost).format("0,0.00")}</Td>
                      </Tr>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>

              <div style={{ padding: "16px 30px" }}>
                <TotalContainer>
                  <Label>Total en la fecha seleccionada: $</Label>
                  <Label weight={500}>
                    {numeral(totalSale).format("0,0.00")}
                  </Label>
                </TotalContainer>

                <TotalContainer>
                  <Label>Total de abono en la fecha seleccionada: $</Label>
                  <Label weight={500}>
                    {numeral(totalAbono).format("0,0.00")}
                  </Label>
                </TotalContainer>

                <TotalContainer>
                  <Label>Total restante: $</Label>
                  <Label weight={500}>
                    {numeral(totalSale - totalAbono).format("0,0.00")}
                  </Label>
                </TotalContainer>
              </div>
            </Container>
          ) : (
            <Container style={{ padding: "12px 0 0 0", marginTop: 20 }}>
              <TableContainer>
                <Table>
                  <tbody>
                    <Tr>
                      <Th>Producto</Th>
                      <Th>Precio de Venta</Th>
                      <Th>Total vendido</Th>
                    </Tr>

                    {data.map((data, index) => (
                      <Tr key={index}>
                        <Td>{data.name}</Td>
                        <Td>{data.sale_price}</Td>
                        <Td>{data.total}</Td>
                      </Tr>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>

              <div style={{ padding: "16px 30px" }}>
                <TotalContainer>
                  <Label>Total en la fecha seleccionada: $</Label>
                  <Label weight={500}>
                    {numeral(totalSale).format("0,0.00")}
                  </Label>
                </TotalContainer>

                <TotalContainer>
                  <Label>Total de abono en la fecha seleccionada: $</Label>
                  <Label weight={500}>
                    {numeral(totalAbono).format("0,0.00")}
                  </Label>
                </TotalContainer>

                <TotalContainer>
                  <Label>Total restante: $</Label>
                  <Label weight={500}>
                    {numeral(totalSale - totalAbono).format("0,0.00")}
                  </Label>
                </TotalContainer>
              </div>
            </Container>
          )}
        </>
      ) : (
        <NotPermissions isLoading={isLoading} />
      )}
    </Fragment>
  );
}

export default withRouter(SalesGeneralReport);

const TotalContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`;
